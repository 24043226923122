* {
  font-size: 11pt;
  font-family: Arial;
}

a {
  font-size: 8pt;
  font-family: Arial;
}

:root {
  --message-color:#002559;
  --warning-color:#002559;
  --error-color:crimson;
}

body {
  background-color: #002559;
  background-repeat: repeat;
  background-size:cover;
}

h1 {
  color:white;
  font-size:16pt;
}

.spacer_small {
  height:20px;
}

.spacer_large {
  height:30px;
}

.center_content {
 text-align: center;
}

.full_width {
  width:100%;
}

.available_country_row {
  text-align:left;
}

.selected_country_row {
  padding-bottom: 5px;
}

.available_country_title {
  text-align:left;
}

.tiny_flag {
  width:25px;
  vertical-align: middle;
}

.available_country_flag {
  padding-right:10px;
}

.available_country_label {
  padding-left: 5px;
  vertical-align: text-bottom;
}

.metric_row {
 text-align:left;
 font-weight:bold;
 padding-top:20px;
}

.attribute_td {
  padding-top:30px;

}

.attribute_div {
  padding-bottom:10px;
  text-align: left;
}

.comparator_row {
  cursor: pointer;
}

.comparator_row:hover .comparator {
  background-color:#DDDDDD;
}

.bar_icon {
  cursor:pointer;
  padding-right:5px;
}

.comparator {
  text-align:left;
  padding-top:10px!important;
  padding-bottom:10px;
  padding-left:5px;
  padding-right:5px;
  font-size:8pt;
  vertical-align:middle;
}

.comparator a {
  font-size:8pt;
}


.comparator_text,
.comparator_text b {
  margin-top: 0;
  color:#444444;
  font-size:8pt;
}

.column_data_text > .comparator_text {
  margin-bottom: 1.5px!important;
  margin-top: 1.5px!important;
}

.comparator_top_left {
  height:10px;
  padding-left:10px;
  border-top:1px solid lightgray;
  border-left:1px solid lightgray;
  border-radius:10px 0px 0px 0px;
  background-color:white;
}

.comparator_top_middle {
  border-top:1px solid lightgray;
  background-color:white;
}

.comparator_top_right {
  border-radius: 0px 10px 00px 0px;
  padding-right:10px;
  border-top:1px solid lightgray;
  border-right:1px solid lightgray;
  background-color:white;
}

.comparator_left {
  padding-left:10px;
  padding-top:0px;
  border-left:1px solid lightgray;
  background-color:white;
}

.comparator_middle {
  padding-top:0px;
  background-color:white;
}

.comparator_right {
  padding-top:0px;
  padding-right:10px;
  border-right:1px solid lightgray;
  background-color:white;
}

.comparator_bottom_left {
  padding-left:10px;
  padding-top:0px;
  border-bottom:1px solid lightgray;
  border-left:1px solid lightgray;
  border-radius:0px 0px 0px 10px;
  background-color:white;
}

.comparator_bottom_middle {
  padding-top:0px;
  border-bottom:1px solid lightgray;
  background-color:white;
}

.comparator_bottom_right {
  border-radius: 0px 0px 10px 0px;
  padding-top:0px;
  padding-right:10px;
  border-bottom:1px solid lightgray;
  border-right:1px solid lightgray;
  background-color:white;
}


.center_center {
  margin:0;
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.rounded {
  border-radius:10px;
}

input,textarea,select,checkbox,search, .button, .css-1s2u09g-control, .css-1pahdxg-control {
  border: 1px solid #999999;
  padding: 5px;
  font-size:10pt;
  border-radius:6px;
  background-color:whitesmoke;
  margin-top:5px;
}
.css-1s2u09g-control,
.css-1s2u09g-control, .css-1pahdxg-control {
  border-radius:6px!important;
  background-color:whitesmoke!important;
  padding: 0!important;
}

textarea,select,input[type=text],input[type=number],input[type=password],input[type=search] {
  width: 100%;
  box-sizing: border-box;
  max-width:550px;
}

.button {
  border: 1px solid #999999;
  padding-left:10px;
  padding-right:10px;
  background-color:#1a4e7f;
  color:white;
  font-weight:bold;
  cursor:pointer;
  margin-left: 20px;
}

.button-inverse {
  background-color:white;
  color:#1a4e7f;
  margin: 20px auto;
}

.disabled_btn {
  background-color:gray;
}


.autocomplete-item {
  border: 1px solid black;
  border-top: none;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid black;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.welcome_text {
  font-size:14pt;
  color:white;
  font-weight:bold;
  text-align:center;
}

.login_wrapper {
  text-align: center;
  vertical-align: center;
}

.login_box {
  margin:auto;
  background-color:white;
  padding:20px;
  border:1px solid gray;
}

.login_box_i {
  color:#555555;
  font-size:9pt;
}

.login_box_a {
  font-size:9pt;
  text-decoration:none;
  color:gray;
}

.login_info_wrapper {
  text-align:center;
}

.login_info_box {

  margin:auto;
  max-width:500px;
  padding-top:20px;
  color:white;
  text-align:left;
  font-size:9pt;
  color:lightgray;
}

.login_info_wrapper_bottom {
  vertical-align: bottom;
}

.login_info_box_bottom {
  color:white;
  text-align:center;
  font-size:8pt;
  padding-top:20px;
}

.login_info_box_bottom b {
  font-size:8pt;
}


.cs_info_text {
  color:white;
}

.cs_box {
  margin:auto;
  background-color:white;
  max-width:750px;
  padding:20px;
  border:1px solid gray;
}

.country_select_cancel_button_wrapper {
  text-align:right;
}

.country_select_cancel_button {
  width: 15px;
  cursor:pointer;
  position:relative;
  top:0px;
  right:0px;
  margin-right: 10px;
  vertical-align: middle;
}

.cs_selected_label {
  text-align:left;
  padding-bottom:10px;
  color:gray;
}

.selected_countries_container {
  text-align:left;
  background-color:#DDDDDD;
  border-radius:5px;
  padding:5px;
  border:1px solid gray;
}

.button_cs_wrapper {
  padding-top: 20px;
}


.button_cs_wrapper .button{
  width:50px;
}

.filter_cs_lable {
  text-align:left;
  padding-top:20px;
  color:gray;
}

.filter_cs_input {
  padding-bottom:20px;
  text-align: left;
}

.select_all_wrapper {
  margin-left: 15%;
}

.column_heading_template {
  text-align:center;
  vertical-align:top;
  display: flex;
}

.comp_flag {
  vertical-align: top;
  text-align:left;
  width:1%;
}

.comp_country_name {
  text-align:left;
  padding-left: 15px;
}

.column_heading_template div {
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.r_box {
  margin:auto;
  background-color: #EEEEEE;
  max-width:750px;
  padding:15px;
  border:1px solid gray;
}

.column_data_template {
  text-align:center;
  vertical-align:top;
  display: flex;
  margin:auto;
  background-color: #fff;
  border:1px solid gray;
}

.column_data {
  padding: 10px;
  flex-direction: column;
  flex: 1;
  text-align: left;
}

.metric_title {
  text-align: left;
  vertical-align: top;
}

.metric_tab {
  text-align:right;
  vertical-align:bottom;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.metric_title_wrapper {
  margin-top: 10px;
  display: flex;
}

.metric_title_wrapper div {
  flex-direction: column;
  flex: 1;
}

.button_wrapper {
  margin: auto;
  max-width: 815px;
  margin-bottom: 20px;
  display: flex;
}

.button_wrapper div {
  text-align: left;
  flex-direction: column;
  flex: 1;
}

.download_button {
  text-align: right!important;
  padding-right: 22px;
}

.admin-country-select-label {
  text-align: left;
}

.admin-country-select {
  max-width: 100%!important;
  text-align: left;
  background-color:white;
}

.fact_sheet_input {
  max-width: 89%!important;
}

.table_metrics {
  width: 100%;
}

.table_metrics tr th  {
  text-align:left;
  font-size:10pt;
}

.comparator_row td {
  text-align:left;
}

.table_btns {
  width: 790px;
  margin: 0 auto;
}

.table_btns button {
  margin: 0 !important;
}

.table_btns_left button {
  margin-right: 20px!important;
}

.table_btns_left {
  text-align:left;
}

.table_btns_right {
  text-align:right;
}

.admin-country-select-label {
  margin-bottom: 2px;
}

.fact_sheet_wrapper {
  margin-top: 1px;
}

.fact_sheet_link {
  margin-left: 40px;
}

.css-1wnsr1i {
  border: none;
  border-radius: 10px;
}

.chart-country_name {
  text-align:left;
  color:gray;
  padding-left:5px;
  vertical-align: middle;
}

.b_chart_val {
  text-align: center!important;
}

.chart_bar_background {
  margin-top: 10px;
  background-color: #EEEEEE;
  width: 100%;
  border-radius: 10px;
  height: 20px;
}

.chart_bar_inner {
  border-radius: 10px;
  height: 20px;
}

.settings-news {
  max-width:750px;
  margin: 0 auto;
  margin-top:10px;
  margin-bottom:10px;
  color:yellow;
  text-align:left;
  border:1px solid yellow;
  border-radius:5px;
  padding:5px;
}

.settings-news textarea {
  text-align: center!important;
  max-width: 100%!important;
  background: none!important;
  border: none!important;
  color: yellow!important;
  resize: none;
}

.modal-wrapper {
  padding: 20px;
}

.login_label {
  text-align: left;
  margin-bottom: 0
}

.logout-btn {
  cursor: pointer;
  display: block;
  margin-top: 10px;
  font-size: 11pt;
  color: #fff;
}

.modal-close {
  text-align: right;
}